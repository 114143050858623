import ContractABI from "./abi.json";
import SpeTokenABI from "./speToken.abi.json";

const bsc = {
  id: 56,
  name: "BNB Smart Chain",
  network: "bsc",
  nativeCurrency: {
    decimals: 18,
    name: "BNB",
    symbol: "BNB",
  },
  rpcUrls: {
    default: {
      http: ["https://rpc.ankr.com/bsc"],
    },
    public: {
      http: ["https://rpc.ankr.com/bsc"],
    },
  },
  blockExplorers: {
    etherscan: {
      name: "BscScan",
      url: "https://bscscan.com",
    },
    default: {
      name: "BscScan",
      url: "https://bscscan.com",
    },
  },
  contracts: {
    multicall3: {
      address: "0xca11bde05977b3631167028862be2a173976ca11",
      blockCreated: 15921452,
    },
  },
};

const bscTestnet = {
  id: 97,
  name: "Binance Smart Chain Testnet",
  network: "bsc-testnet",
  nativeCurrency: {
    decimals: 18,
    name: "BNB",
    symbol: "tBNB",
  },
  rpcUrls: {
    default: {
      http: ["https://data-seed-prebsc-1-s1.binance.org:8545"],
    },
    public: {
      http: ["https://data-seed-prebsc-1-s1.binance.org:8545"],
    },
  },
  blockExplorers: {
    etherscan: {
      name: "BscScan",
      url: "https://testnet.bscscan.com",
    },
    default: {
      name: "BscScan",
      url: "https://testnet.bscscan.com",
    },
  },
  contracts: {
    multicall3: {
      address: "0xca11bde05977b3631167028862be2a173976ca11",
      blockCreated: 17422483,
    },
  },
  testnet: true,
};

export const environment = {
  type: "testnet",
  contract: {
    address: "0xa260D4ef9307e7Bd2C2fd9395D868caaACB9a78b",
    abi: ContractABI,
  },
  speContract: {
    address: "0x55d398326f99059fF775485246999027B3197955",
    abi: SpeTokenABI,
  },
  chains: [bsc],
  chainData: {
    currentChainId: 56,
    hexChainId: "0x38",
    chainName: "BSC Mainnet",
    name: "BNB",
    symbol: "BNB",
    rpcUrls: ["https://bsc-dataseed.binance.org"],
    blockExplorerUrls: ["https://bscscan.com/"],
  },
};

// import ContractABI from "./abi.json";
// import SpeTokenABI from "./speToken.abi.json";
// export const environment = {
//   type: "testnet",
//   contract: {
//     address: "0xf0cb952ecbb4b9c19172b4f3743068e1f96521ea",
//     abi: ContractABI,
//   },
//   speContract: {
//     address: "0x963ae28aC4698dC1A938049F96C6f15D59DC9EDA",
//     abi: SpeTokenABI,
//   },
//   chainData: {
//     currentChainId: 97,
//     hexChainId: "0x61",
//     chainName: "BSC Testnet",
//     name: "tBNB",
//     symbol: "tbnb",
//     rpcUrls: ["https://data-seed-prebsc-1-s3.binance.org:8545/"],
//     blockExplorerUrls: ["https://testnet.bscscan.com/"],
//   },
// };
