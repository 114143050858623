// @mui material components

// @mui material components
import { CircularProgress, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard React components
import { environment } from "@/lib";
import SoftBox from "@components/SoftBox";
import SoftButton from "@components/SoftButton";
import SoftTypography from "@components/SoftTypography";
import { useCart } from "@core/hooks";
import { toMoment } from "@core/utils/time";
import { useAuthStore } from "@store/AuthStore";
import { MessageStore } from "@store/MessageStore";
import { useOrderStore } from "@store/OrderStore";
import { useState } from "react";
import { NotificationManager } from "react-notifications";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAccount, useContract, useProvider, useSigner } from "wagmi";
import Web3 from "web3";

function CheckoutSide({ btnText, onClick, variant, cartItems = [] }) {
  const dispatch = useDispatch();

  const [isGiftCard, setIsGiftCard] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSetGiftCard = () => setIsGiftCard(!isGiftCard);

  const navigate = useNavigate();
  const orderStore = useOrderStore();
  const authStore = useAuthStore();
  const user = authStore.user();
  const userId = authStore.authId;

  const orders = orderStore.items;

  const mintedOrders = orders?.filter((o) => (o?.type || "mint") === "mint");
  const mintedPlanIds = mintedOrders?.map((o) => o?.planId);

  const { isConnected, address } = useAccount();

  const cart = useCart();
  const totalQuantity = cartItems?.length || 0;
  const totalPrice = cartItems?.reduce((acc, item) => acc + item.price, 0);

  const { data: signer } = useSigner({});
  const provider = useProvider({
    chainId: environment.chainData.currentChainId,
  });

  const planContract = useContract({
    address: environment.contract.address,
    abi: environment.contract.abi,
    signerOrProvider: !address ? provider : signer,
  });

  const speContract = useContract({
    address: environment.speContract.address,
    abi: environment.speContract.abi,
    signerOrProvider: !address ? provider : signer,
  });

  const onHandlePay = async (e) => {
    if (!isConnected) {
      dispatch(MessageStore.setOpenModal(true));
    } else {
      setLoading(true);

      const hasAnyMintedItem = mintedPlanIds?.some((id) =>
        cartItems?.some((item) => item.id === id)
      );

      if (hasAnyMintedItem && !isGiftCard) {
        setLoading(false);
        NotificationManager.warning(
          "Vous avez déjà acheté certains des articles de votre panier",
          "Déjà Frappé",
          3000
        );
        return;
      }

      try {
        const totalAmount = Web3.utils.toWei(totalPrice.toString(), "ether");

        const txSPE = await speContract.approve(
          environment.contract.address,
          totalAmount.toString()
        );
        const txSPEResult = await txSPE.wait();

        const paySuccess = await planContract.sendTokens(
          totalAmount.toString()
        );

        const paySuccessResult = await paySuccess.wait();

        const giftCode = String(toMoment().unix());

        cartItems.forEach(async (item, index) => {
          await orderStore.createItem({
            paymentHash: String(paySuccessResult?.transactionHash),
            tokenHash: String(txSPEResult?.transactionHash),
            userId: [userId],
            card: item,
            productId: item.id,
            planId: item.id,
            reward: item.reward,
            quantity: 1,
            price: item.price,
            type: isGiftCard ? "gift" : "mint",
            walletAddress: address,
            giftCode: isGiftCard ? giftCode : "",
            createdBy: userId,
            status: "active",
          });

          if (cartItems.length === index + 1) {
            setLoading(false);
            NotificationManager.success("Paiement Réussi", "Succès", 3000);
          }
        });

        cart.clearCart();
        const totalCards = !isGiftCard
          ? parseFloat(user?.totalCards || 0) + parseFloat(totalQuantity)
          : parseFloat(user?.totalCards || 0);

        await authStore.updateItem({
          ...user,
          totalSpending:
            parseFloat(user?.totalSpending || 0) + parseFloat(totalPrice),
          totalCards: totalCards,
        });

        await authStore.getItem(userId);

        navigate("/plans");
      } catch (error) {
        setLoading(false);

        NotificationManager.warning(
          error?.message || error,
          "Veuillez réessayer",
          3000
        );
        console.log("pay_catch_error", error);
      }
    }
  };

  return (
    <Card
      sx={{
        height: "100%",
        paddingBottom: "15px",
      }}
    >
      <SoftBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        pt={2}
        px={2}
      >
        <Typography variant="h5">Total Du Panier</Typography>
      </SoftBox>
      <Divider />

      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={4}
        pt={2}
        px={2}
        sx={{ borderTop: "1px solid #eee" }}
      >
        <SoftTypography variant="h6" color="text" fontWeight="bold">
          Total
        </SoftTypography>
        <SoftTypography variant="h6" color="text" fontWeight="bold">
          ${totalPrice}
        </SoftTypography>
      </SoftBox>

      <SoftBox
        display="flex"
        alignItems="center"
        variant="gradient"
        color="info"
        sx={{ marginLeft: "20px", marginTop: "20px" }}
      >
        <Checkbox
          checked={isGiftCard}
          onChange={handleSetGiftCard}
          sx={{
            border: "1px solid #0842ac!important",
          }}
        />

        <SoftTypography
          component="a"
          href="#"
          variant="button"
          color="info"
          fontWeight="bold"
          textGradient
        >
          Acheter En Carte Cadeau
        </SoftTypography>
      </SoftBox>

      <SoftBox my={4} mx={2} mb={1} minWidth="3rem">
        <SoftButton
          onClick={() => onHandlePay()}
          // component={Link}
          // to="/authentication/payment"
          variant="gradient"
          fullWidth
          type="submit"
          color="info"
          disabled={
            totalQuantity <= 0 || loading || user?.status !== "approved"
          }
        >
          <div
            className="d-flex gap-10 align-items-center justify-content-center"
            style={{ gap: 10 }}
          >
            {loading && (
              <CircularProgress color="secondary" size={18} className="mr-5" />
            )}

            {isConnected ? "Payez Maintenant" : "Connecter Le Portefeuille"}
          </div>
        </SoftButton>
      </SoftBox>
    </Card>
  );
}

export default CheckoutSide;
