

import { useEffect, useState } from 'react';

// react-router components
import { useLocation, useNavigate } from 'react-router-dom';

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// @material-ui core components
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AppBar from '@mui/material/AppBar';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';

// Soft UI Dashboard React components
import SoftBox from '@components/SoftBox';

// Soft UI Dashboard React examples
import Breadcrumbs from '@examples/Breadcrumbs';

// Custom styles for DashboardNavbar
import {
    navbar,
    navbarContainer,
    navbarMobileMenu,
    navbarRow,
} from '@examples/Navbars/DashboardNavbar/styles';

import { useDispatch } from 'react-redux';

// Soft UI Dashboard React context
import {
    setMiniSidenav,
    setOpenConfigurator,
    setTransparentNavbar,
    useSoftUIController,
} from '@context';

// Images
import { useCart } from '@core/hooks';
import { Logout } from '@mui/icons-material';
import Badge from '@mui/material/Badge';
import { useAuthStore } from '@store/AuthStore';
import { removeItem } from '@store/CartPlanSlice/CartStoreSlice';

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator } =
    controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split('/').slice(1);
  const cart = useCart();

  const totalItemsInCart = cart.cartItems.length;
  const authStore = useAuthStore();
  const user = authStore.user();
  const dataDispatch = useDispatch();

  const handleSignOut = e => {
    e.preventDefault();
    authStore.logout();
  };

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType('sticky');
    } else {
      setNavbarType('static');
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar
      );
    }

    /**
     The event listener that's calling the handleTransparentNavbar function when
     scrolling the window.
    */
    window.addEventListener('scroll', handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener('scroll', handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);

  const handleOpenMenu = event => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  const navigate = useNavigate();

  const goToCheckOut = () => {
    navigate('/plans/verifier');
  };
  const itemRemoveHandler = item => {
    dispatch(removeItem(item));
  };
  // Render the notifications menu

  return (
    <AppBar
      position={absolute ? 'absolute' : navbarType}
      color='inherit'
      sx={theme => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <Toolbar sx={theme => navbarContainer(theme)}>
        <SoftBox
          color='inherit'
          mb={{ xs: 1, md: 0 }}
          sx={theme => navbarRow(theme, { isMini })}
        >
          <Breadcrumbs
            icon='home'
            title={route[route.length - 1]}
            route={route}
            light={light}
          />
        </SoftBox>

        {isMini ? null : (
          <SoftBox sx={theme => navbarRow(theme, { isMini })}>
            <SoftBox
              color={light ? 'white' : 'inherit'}
              sx={{ position: 'relative' }}
            >
              <IconButton
                size='small'
                onClick={handleSignOut}
                sx={{
                  color: '#0842ac',
                  height: '32px',
                  width: '32px',
                  border: '1px solid',
                  borderColor: '#DAE2ED',
                  backgroundColor: '#fff',
                  margin: '0px 0px 0px 10px',
                  position: 'relative',
                  ':&hover': { backgroundColor: '#fff' },
                }}
              >
                <Logout />
              </IconButton>

              <IconButton
                size='small'
                color='inherit'
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon className={light ? 'text-white' : 'text-dark'}>
                  {miniSidenav ? 'menu_open' : 'menu'}
                </Icon>
              </IconButton>
            </SoftBox>

            {/* /* SHOPPING CART SECTIN */}

            <Badge badgeContent={totalItemsInCart} color='primary'>
              <IconButton
                size='small'
                color='inherit'
                sx={{
                  color: '#0842ac',
                  height: '32px',
                  width: '32px',
                  border: '1px solid',
                  borderColor: '#DAE2ED',
                  backgroundColor: '#fff',
                  padding: '2px',
                  margin: '0px 0px 0px 10px',
                }}
                aria-controls='notification-menu'
                aria-haspopup='true'
                onClick={handleOpenMenu}
              >
                <ShoppingCartIcon onClick={goToCheckOut} />
              </IconButton>
            </Badge>

            {/* <Avatar alt='Remy Sharp' src={AVATAR} sx={{ marginLeft: '20px' }} /> */}
          </SoftBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
