

// @mui material components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard React components

// Soft UI Dashboard React examples
import DashboardLayout from '@examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '@examples/Navbars/DashboardNavbar';

// Billing page components

import { Delete, ShoppingCart } from '@mui/icons-material';

import MiniStatisticsCard from '@examples/Cards/StatisticsCards/MiniStatisticsCard';
import BUSD from '../../../../assets/images/BUSD.png';


import { useCart } from '@core/hooks';
import { IconButton, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { useOrderStore } from '@store/OrderStore';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CheckoutSide from './CheckoutSide.js';

const TAX_RATE = 0.07;

function ccyFormat(num) {
  return `${num.toFixed(2)}`;
}

function priceRow(qty, unit) {
  return qty * unit;
}

function createRow(desc, bv, csr, qty, unit) {
  const price = priceRow(qty, unit);
  return { desc, bv, csr, unit, price, qty };
}

function subtotal(items) {
  return items.map(({ price }) => price).reduce((sum, i) => sum + i, 0);
}

const rows = [createRow('Paperclips (Box)', 0, 2, 5, 10)];

const invoiceSubtotal = subtotal(rows);
const invoiceTaxes = TAX_RATE * invoiceSubtotal;
const invoiceTotal = invoiceTaxes + invoiceSubtotal;

const Checkout = () => {
  const [cartItems, setCartItems] = useState([]);

  const navigate = useNavigate();
  function cOut(e) {
    e.preventDefault();
    alert('Goingt to Checkout');
    navigate('/authentication/payment');
  }

  const cart = useCart();

  const orderStore = useOrderStore();
  const orders = orderStore.items;
  console.log('orders', orders);

  useEffect(() => {
    setCartItems(cart.cartItems);
  }, [cart.cartItems?.length]);

  useEffect(() => {
    orderStore.fetchItems();
  }, []);

  const onRemoveItem = item => {
    cart.removeItem(item.id);
    setCartItems(cartItems?.filter(cartItem => cartItem.id !== item.id));
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={2}>
        <Grid item xs={12} lg={8} sx={{ marginBottom: '10px' }}>
        <MiniStatisticsCard
                title={{ text: 'Procurez-vous votre carte de membre en réglant avec des USDT BEP20' }}
                // count={totalTokens || 0}
                // percentage={{ color: 'success', text: '+55%' }}
                icon={{ color: 'info' }}
                image={BUSD}
              />
          <TableContainer component={Paper}>
            <Table sx={{ marginTop: '10px' }} aria-label='spanning table'>
              <TableRow>
                <TableCell colSpan={6}>
                  <Typography
                    fontWeight={'bold'}
                    display={'flex'}
                    justifyContent={'flex-start'}
                    alignItems={'center'}
                    color={`#0842ac`}
                    sx={{ fontSize: ' 1.875rem' }}
                  >
                    <ShoppingCart sx={{ marginRight: '10px', color: '#555' }} />
                    Détails Du Panier
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Description</TableCell>
                <TableCell align='right'>Prix</TableCell>
                <TableCell align='right'>Retirer</TableCell>
              </TableRow>
              <TableBody>
                {cartItems.map(cartItem => (
                  <TableRow key={cartItem.id}>
                    <TableCell>{cartItem.title}</TableCell>
                    <TableCell align='right'>${cartItem.price}</TableCell>
                    <TableCell align='right'>
                      <IconButton onClick={() => onRemoveItem(cartItem)}>
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}

                {cartItems.length == 0 && (
                  <TableRow>
                    <TableCell colSpan={3} align='center'>
                      Aucun Article Dans Le Panier
                    </TableCell>
                  </TableRow>
                )}

                {/* <TableRow>
              <TableCell>Tax</TableCell>
              <TableCell align='right'>{`${(TAX_RATE * 100).toFixed(
                0
              )} %`}</TableCell>
              <TableCell align='right'>{ccyFormat(invoiceTaxes)}</TableCell>
            </TableRow> */}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} md={4}>
          <CheckoutSide
            btnText='Vérifier'
            onClick={cOut}
            cartItems={cartItems}
          />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default Checkout;
