

// @mui material components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard React components
import SoftBox from '@components/SoftBox';
import BUSD from '../../assets/images/BUSD.png';

// Soft UI Dashboard React examples
import Footer from '@examples/Footer';
import DashboardLayout from '@examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '@examples/Navbars/DashboardNavbar';

// Billing page components
import MiniStatisticsCard from '@examples/Cards/StatisticsCards/MiniStatisticsCard';

import { Typography } from '@mui/material';

import { PLAN_CARDS } from '@core/constants/plans';
import { useCart } from '@core/hooks';
import KcyVerify from '@layouts/notifications/KcyVerify';
import { CartPlanStore } from '@store/CartPlanSlice';
import { useOrderStore } from '@store/OrderStore';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PlanCard from './components/plan-card';

function PackagePlans() {
  const [plan, setPlan] = useState(false);
  const orderStore = useOrderStore();
  const dispatch = useDispatch();

  const orders = orderStore.items;
  const plans = CartPlanStore;

  const packPlan = useSelector(state => state.cartStoreNew);
  console.log(packPlan, 'plans11');
  const navigate = useNavigate();
  const cart = useCart();

  const onAddItem = item => {
    cart.addItem(item);
  };

  // const addItemToCartHandler = ({ id, title, price }) => {
  //   dispatch(addItem(id, title, price));
  // };

  useEffect(() => {
    orderStore.fetchItems();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <KcyVerify />
      <Grid item xs={12} sm={6} xl={4}>
        <MiniStatisticsCard
          title={{
            text: 'Procurez-vous votre carte de membre en réglant avec des USDT BEP20',
          }}
          // count={totalTokens || 0}
          // percentage={{ color: 'success', text: '+55%' }}
          icon={{ color: 'info' }}
          image={BUSD}
        />
      </Grid>
      <SoftBox mt={4}>
        <SoftBox mb={1.5}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <SoftBox
                variant='gradient'
                marginLeft='auto'
                borderRadius='md'
                padding='20px'
                color='info'
              >
                <Typography
                  fontWeight={600}
                  color={`#0842ac`}
                  sx={{ fontSize: { sx: '20px !important', md: '30px' } }}
                >
                  Voici les différents cartes!
                </Typography>
                <Typography variant='button' fontWeight={400} color={`#0842ac`}>
                  VEUILLEZ CHOISIR PARMI LES FORFAITS CI-DESSOUS.
                </Typography>
              </SoftBox>
            </Grid>
            {PLAN_CARDS.map((card, index) => {
              const isAdded = cart.findItem(card.id);
              return (
                <Grid item xs={12} lg={4} key={card.id}>
                  <PlanCard
                    isPurchased={
                      orders.find(order => order.planId === card.id)?.id ||
                      false
                    }
                    title={card.title}
                    reward={`RÉCOMPENSE = ${card.reward} popol`}
                    price={`$${card.price}`}
                    buttonTxt={isAdded ? 'AJOUTÉ' : 'Ajouter au panier'}
                    onClick={() => onAddItem(card)}
                    // onClick={addItemToCartHandler}
                  />
                </Grid>
              );
            })}
          </Grid>
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default PackagePlans;
