

import { useEffect, useState } from 'react';
// import './styles/sass/style.scss';

// react-router components
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

// @mui material components

// Soft UI Dashboard React components

// Soft UI Dashboard React examples
import Sidenav from '@examples/Sidenav';

// Soft UI Dashboard React themes

// RTL plugins

// Soft UI Dashboard React routes
import routes from './routes';

// Soft UI Dashboard React contexts
import {
    setMiniSidenav,
    setOpenConfigurator,
    useSoftUIController,
} from '@context/index';

// Images
import brand from '@assets/images/logo.jpeg';
import ResetPassword from '@layouts/authentication/reset-password';
import SignIn from '@layouts/authentication/sign-in';
import SignUp from '@layouts/authentication/sign-up';
import { useAuthStore } from '@store/AuthStore';

import { SupermanPage } from '@layouts/authentication/Superman';
import { VerifyOtpPage } from '@layouts/authentication/VerifyOtp';
import ForgotPasswordPage from '@layouts/authentication/forgot-password';
import NewPassword from '@layouts/authentication/new-password';
import 'react-notifications/lib/notifications.css';

export default function AppWithRoutes() {
  const authStore = useAuthStore();
  const authId = authStore?.authId;
  const user = authStore.user();

  const isResetting = window.location.href.includes('/reset-password');

  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor } =
    controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  // // Cache for the rtl
  // useMemo(() => {
  //   const cacheRtl = createCache({
  //     key: 'rtl',
  //     stylisPlugins: [rtlPlugin],
  //   });

  //   setRtlCache(cacheRtl);
  // }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute('dir', direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = allRoutes =>
    allRoutes.map(route => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      } else {
        <Route path='*' element={<SignUp to='/authentication/sign-in' />} />;
      }

      return null;
    });

  return (
    <>
      {user?.verified === true && !isResetting ? (
        <>
          <Sidenav
            color={sidenavColor}
            brand={brand}
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Routes>
            {getRoutes(routes)}
            <Route
              path='/authentication/otp'
              element={<VerifyOtpPage to='/authentication/otp' />}
            />
            <Route path='*' element={<Navigate to='/Tableau-de-bord' />} />
          </Routes>
        </>
      ) : (
        <Routes>
          <Route path='*' element={<SignIn to='/authentication/sign-in' />} />
          <Route
            path='/authentication/sign-up'
            element={<SignUp to='/authentication/sign-up' />}
          />
          <Route
            path='/sponsor/:referralId'
            element={<SignUp to='/authentication/sign-up' />}
          />
          <Route
            path='/authentication/otp'
            element={<VerifyOtpPage to='/authentication/otp' />}
          />
          <Route path='/superman' element={<SupermanPage to='/superman' />} />
          <Route
            path='/authentication/forgot-password'
            element={
              <ForgotPasswordPage to='/authentication/forgot-password' />
            }
          />
          <Route
            path='/authentication/reset-password/:code'
            element={<ResetPassword to='/authentication/reset-password' />}
          />
          <Route
            path='/authentication/new-password'
            element={<NewPassword to='/authentication/new-password' />}
          />
        </Routes>
      )}
    </>
  );
}
