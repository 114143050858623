import { useEffect, useState } from 'react';

// react-router-dom components
import { useNavigate, useParams } from 'react-router-dom';

// @mui material components
import Card from '@mui/material/Card';

// Soft UI Dashboard React components
import SoftBox from '@components/SoftBox';

// Authentication layout components

// Images
import { PrimaryButton } from '@components/Button';
import SoftTypography from '@components/SoftTypography';
import { FormInput } from '@core/forms';
import { ValidatorService } from '@core/services';
import { Grid } from '@mui/material';
import { useAuthStore } from '@store/AuthStore';
import { useForm } from 'react-hook-form';
import { NotificationManager } from 'react-notifications';

function ProfileUpdate() {
  const navigate = useNavigate();
  const [agreement, setAgremment] = useState(true);
  const [loading, setLoading] = useState(false);

  const { referralId } = useParams();
  const authStore = useAuthStore();
  const user = authStore.user();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    watch,
    setValue,
  } = useForm();

  useEffect(() => {
    if (user?.id) {
      setValue('name', user?.name);
      setValue('email', user?.email);
      setValue('phone', user?.phone);
      setValue('region', user?.region);
      setValue('origin', user?.origin);
      setValue('subdivision', user?.subdivision);
      setValue('village', user?.village);
      setValue('walletAddress', user?.walletAddress);
    }
  }, [user?.id]);

  const onSubmit = async data => {
    setLoading(true);

    try {
      await authStore.updateItem({
        ...user,
        ...data,
      });
      await authStore.getItem(user?.id);

      setLoading(false);

      NotificationManager.success('KYC soumis avec succès', 'Success', 3000);
    } catch (error) {
      const message = error.message
        .replace('Firebase:', '')
        .replace('(auth/email-already-in-use).', '');

      setError('email', {
        type: 'manual',
        message: message,
      });
      setLoading(false);
      return;
    }
  };

  return (
    <Card>
      <SoftBox pt={1} pb={3} px={3}>
        <SoftTypography
          variant='h3'
          fontWeight='medium'
          sx={{ textAlign: 'center', padding: '30px', color: '#1110a1' }}
        >
          Mettez à jour vos informations !
        </SoftTypography>
        <Grid
          container
          spacing={2}
          alignItems='center'
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Grid item xs={12} md={6} lg={8}>
            <SoftBox
              component='form'
              role='form'
              onSubmit={handleSubmit(onSubmit)}
            >
              <SoftBox mb={2} sx={{ display: 'flex', gap: '10px' }}>
                <FormInput
                  type='text'
                  control={register('name', {
                    required: 'Nom est requise',
                  })}
                  tepy='text'
                  label='Nom et prénom'
                  // placeholder='Name'
                  error={errors.name}
                  sx={{ margin: '3px' }}
                  withoutGroup
                />
                <FormInput
                  type='email'
                  control={register('email', {
                    required: 'Email est requise',
                    validate: ValidatorService.email,
                  })}
                  className='form-control'
                  label='Email'
                  // placeholder='Email'
                  error={errors.email}
                  disabled={true}
                  withoutGroup
                />
              </SoftBox>
              <SoftBox mb={2} sx={{ display: 'flex', gap: '10px' }}>
                <FormInput
                  type='text'
                  control={register('region', {
                    required: 'La région est requise',
                  })}
                  tepy='text'
                  label='Région'
                  // placeholder='Région'
                  error={errors.region}
                  withoutGroup
                />
                <FormInput
                  type='text'
                  control={register('origin', {
                    required: 'Division est requise',
                  })}
                  tepy='text'
                  label='Division'
                  // placeholder='Division d origine'
                  error={errors.origin}
                  withoutGroup
                />
              </SoftBox>
              <SoftBox mb={2} sx={{ display: 'flex', gap: '10px' }}>
                <FormInput
                  type='text'
                  label='Subdivision'
                  control={register('subdivision', {
                    required: 'Subdivision est requise',
                  })}
                  tepy='text'
                  // placeholder='Subdivision of origin'
                  error={errors.subdivision}
                  withoutGroup
                />
                <FormInput
                  type='text'
                  label='Village'
                  control={register('village', {
                    required: 'Village est requise',
                  })}
                  tepy='text'
                  // placeholder='Village'
                  error={errors.village}
                  withoutGroup
                />
              </SoftBox>
              <SoftBox mb={2} sx={{ display: 'flex', gap: '10px' }}>
                <FormInput
                  type='text'
                  label='Adresse Du Portefeuille'
                  control={register('walletAddress', {
                    required: 'Adresse Du Portefeuille',
                  })}
                  className='form-control'
                  // placeholder='eg: 0x4567bdfg33'
                  error={errors.walletAddress}
                  withoutGroup
                />
              </SoftBox>

              <SoftBox mt={4} mb={1}>
                <PrimaryButton
                  variant='gradient'
                  fullWidth
                  type='submit'
                  color='info'
                  loading={loading}
                >
                  MISE À JOUR
                </PrimaryButton>
              </SoftBox>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </Card>
  );
}

export default ProfileUpdate;
