

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React components
import SoftBox from "@components/SoftBox";

// Soft UI Dashboard React base styles
import borders from "@assets/theme/base/borders";

// Images

import { useForm } from "react-hook-form";

function PaymentOptions() {
  const { borderWidth, borderColor } = borders;
  const { register } = useForm();
  return (
    <SoftBox mb={2} mt={2}>
      <Grid
        container
        spacing={3}
        p={1}
        pl={2}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid xs={12} lg={6}>
          <SoftBox pt={2} pl={2}>
            <SoftBox width="100%">
              <label style={{ fontSize: "14px", paddingLeft: "8px" }}>
                Card Holder Name:
              </label>
              <input
                {...register("cardholdername")}
                placeholder="Card Holder Name"
                style={{
                  width: "100%",
                  height: "36px",
                  borderRadius: "6px",
                  border: "1px solid #ddd",
                  paddingLeft: "10px",
                  fontSize: "14px",
                  letterSpacing: "0.5px",
                }}
              />
            </SoftBox>
          </SoftBox>
        </Grid>
        <Grid xs={12} lg={6}>
          <SoftBox
            pt={2}
            pl={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <SoftBox width="100%">
              <label style={{ fontSize: "14px", paddingLeft: "8px" }}>
                Card Number
              </label>
              <input
                {...register("cardnumber")}
                placeholder="1234 1234 1234 1234"
                style={{
                  width: "100%",
                  height: "36px",
                  borderRadius: "6px",
                  border: "1px solid #ddd",
                  paddingLeft: "10px",
                  fontSize: "14px",
                  letterSpacing: "0.5px",
                }}
              />
            </SoftBox>
          </SoftBox>
        </Grid>
        <Grid xs={12} lg={6}>
          <SoftBox
            pt={2}
            pl={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <SoftBox width="100%">
              <label style={{ fontSize: "14px", paddingLeft: "8px" }}>
                Expiry Date
              </label>
              <input
                {...register("expirydate")}
                placeholder="MM/YY"
                style={{
                  width: "100%",
                  height: "36px",
                  borderRadius: "6px",
                  border: "1px solid #ddd",
                  paddingLeft: "10px",
                  fontSize: "14px",
                  letterSpacing: "0.5px",
                }}
              />
            </SoftBox>
          </SoftBox>
        </Grid>
        <Grid xs={12} lg={6}>
          <SoftBox
            pt={2}
            pl={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <SoftBox width="100%">
              <label style={{ fontSize: "14px", paddingLeft: "8px" }}>
                CVV
              </label>
              <input
                {...register("cvv")}
                placeholder="CVV"
                style={{
                  width: "100%",
                  height: "36px",
                  borderRadius: "6px",
                  border: "1px solid #ddd",
                  paddingLeft: "10px",
                  fontSize: "14px",
                  letterSpacing: "0.5px",
                }}
              />
            </SoftBox>
          </SoftBox>
        </Grid>
      </Grid>
    </SoftBox>
  );
}

export default PaymentOptions;
