

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React components
import SoftBox from "@components/SoftBox";

// Soft UI Dashboard React base styles
import borders from "@assets/theme/base/borders";

// Images
import walletlogo from "@assets/images/logos/wallet.svg";

import {
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
} from "@mui/material";
import { MessageStore } from "@store/MessageStore";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useAccount } from "wagmi";
import CheckoutSide from "../checkout/CheckoutSide";
import PaymentOptions from "./PamentOptions";

function SelectPaymentMethod({ btnSelectText, onClick }) {
  const { borderWidth, borderColor } = borders;
  const { register } = useForm();
  const dispatch = useDispatch();
  const { isConnected, address } = useAccount();
  const [choosePayment, setChoosePayment] = useState("wallet");
  const handleOptionChange = (event) => {
    setChoosePayment(event.target.value);
  };

  const payByCard = (e) => {
    e.preventDefault();

    alert("please add Card detials");
  };
  const walletConnectHandler = (e) => {
    if (!isConnected) {
      dispatch(MessageStore.setOpenModal(true));
    }
    e.preventDefault();
  };

  return (
    <Card mb={2}>
      <FormControl>
        <SoftBox
          pt={2}
          px={2}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <FormLabel>Please Select Payment Method</FormLabel>
        </SoftBox>

        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
        >
          <SoftBox p={2} sx={{ width: "100%" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={8}>
                <Grid container spacing={2}>
                  {/* <Grid item xs={12} md={6}>
                    <SoftBox
                      border={`${borderWidth[1]} solid ${borderColor}`}
                      borderRadius='lg'
                      display='flex'
                      justifyContent='space-between'
                      alignItems='center'
                      px={3}
                      py={2}
                    >
                      <FormControlLabel
                        ml={2}
                        value='card'
                        control={<Radio mt={3} sx={{ marginTop: '4px' }} />}
                        label='Credit / Debit Card'
                        sx={{ lineHeight: '0px', width: '100%' }}
                        checked={choosePayment === 'card'}
                        onChange={handleOptionChange}
                      />
                      <SoftBox
                        component='img'
                        src={masterCardLogo}
                        height='100%'
                        alt='master card'
                        width='17%'
                        mr={1}
                      />
                    </SoftBox>
                  </Grid> */}
                  <Grid item xs={12} md={6}>
                    <SoftBox
                      border={`${borderWidth[1]} solid ${borderColor}`}
                      borderRadius="lg"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      px={3}
                      py={2}
                      fullwidth
                    >
                      <FormControlLabel
                        ml={2}
                        value="wallet"
                        control={<Radio mt={3} sx={{ marginTop: "4px" }} />}
                        label="Crypto Wallet"
                        sx={{ lineHeight: "0px", width: "100%" }}
                        checked={choosePayment === "wallet"}
                        onChange={handleOptionChange}
                      />
                      <SoftBox
                        component="img"
                        src={walletlogo}
                        height="100%"
                        alt="wallet"
                        width="15%"
                        mr={1}
                      />
                    </SoftBox>
                  </Grid>
                </Grid>
                {choosePayment === "card" ? <PaymentOptions /> : null}
              </Grid>
              <Grid item xs={12} lg={4}>
                <CheckoutSide
                  btnText={
                    choosePayment === "wallet" ? "Connect Wallet" : "Pay now"
                  }
                  onClick={
                    choosePayment === "card" ? payByCard : walletConnectHandler
                  }
                  variant="gradient"
                />
              </Grid>
            </Grid>
          </SoftBox>
        </RadioGroup>
      </FormControl>
    </Card>
  );
}

export default SelectPaymentMethod;
